/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PortfolioPlanFormProps } from './PortfolioPlan-form.types';
import { theme } from '../../../../theme/theme';
import PortfolioPlan from '../../../../models/Portfolio';

export default function ViewPortfolioPlans({ currentPortfolioPlans, isUpdate, isEyeIconClicked }: PortfolioPlanFormProps) {
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };

  const { t } = useTranslation();

  const defaultValues = useMemo<PortfolioPlan>(
    () => ({
      title: currentPortfolioPlans?.title || '',
      description: currentPortfolioPlans?.description || '',
      duration: currentPortfolioPlans?.duration || 1,
      isPaid: currentPortfolioPlans?.isPaid !== undefined ? currentPortfolioPlans?.isPaid : 1,
      price: currentPortfolioPlans?.price || 0,
    }),
    [currentPortfolioPlans],
  );
  const methods = useForm<PortfolioPlan>({
    defaultValues,
    mode: 'all',
  });

  const { watch, reset } = methods;

  const values = watch();
  console.log('currentPortfolioPlans', currentPortfolioPlans?.isPaid);

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentPortfolioPlans]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentPortfolioPlans?.title || '',
      description: currentPortfolioPlans?.description || '',
      duration: currentPortfolioPlans?.duration || 1,
      isPaid: currentPortfolioPlans?.isPaid !== undefined ? currentPortfolioPlans?.isPaid : 1,
      price: currentPortfolioPlans?.price || 0,
    };
    methods.reset(updatedDefaultValues);
  }, [currentPortfolioPlans, isUpdate]);

  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('portfolioPlans.form_fields.title.placeholder')}</Typography>
                  <label style={customStyle}>{values?.title}</label>
                </div>
                <div className="col">
                  <Typography>{t('portfolioPlans.form_fields.description.placeholder')}</Typography>
                  <label style={customStyle}>{values?.description}</label>
                </div>
                <div className="col">
                  <Typography>{t('portfolioPlans.form_fields.duration.placeholder')}</Typography>
                  <label style={customStyle}>{values?.duration === 1 ? `${values?.duration} month` : `${values?.duration} months`}</label>
                </div>
                <div className="col">
                  <Typography>{t('portfolioPlans.form_fields.price.placeholder')}</Typography>
                  <label style={customStyle}>{values?.price}</label>
                </div>

                <div className="col">
                  <Typography>{t('portfolioPlans.form_fields.ispaid.placeholder')}</Typography>
                  <label style={customStyle}>{values?.isPaid == 1 ? 'Yes' : 'No'}</label>
                </div>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
