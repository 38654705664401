export const ENDPOINTS = {
  SIGNIN: 'v2/auth',
  SIGNOUT: 'v2/auth',
  REFRESH_TOKEN: 'v2/auth/refresh-token',
  DASHBOARD: 'dashboard',
  CHANGE_PASSWORD: 'v2/self/password/',
  PROFILE: 'v2/self',
  BUGS: 'bug-reports',
  ARTICLES: 'articles',
  OPPORTUNITIES: 'opportunities',
  ARTICLE: 'article',
  OPPORTUNITY: 'opportunity',
  COUNTIRES: 'countries',
  USERS: 'v2/users',
  FLAGS: 'flags',
  BUGS_COUNTER: 'bug-reports/count',
  APPLICANTS_COUNTER: 'applicants/count',
  SEEN_APPLICANTS: 'applicants/change-seen',
  CHANGE_ARTICLE_STATUS: 'articles/change-status/',
  REPORTINGS_COUNTER: 'flags/count',
  SSEVENTS: 'ssevents',
  PUBLICATIONS: 'publications/',
  COMMENTS: 'publications-comments/',
  USERSLIST: 'v2/users/',
  COMPANIES: 'campaigns',
  ACTIVE: 'campaigns/id/active',
  UNIVERSITIES: 'universities',
  PROFILES: 'profiles',
  SETTINGS: 'settings',
  SYSTEMUSERS: 'v2/subAdmin',
  FORGOTPASSWORD: 'reset-password',
  PERMISSION: 'v2/subAdmin/getUserPermission',
  APPROVED_ADS_USERS: 'v2/servicePlan/getAcceptedAdsRequests',
  PENDING_ADS_USERS: 'v2/servicePlan/getAdsRequests',
  ACCEPT_REJECT_PENDING_ADS: 'v2/servicePlan/acceptRejectRequest',
  VIEW_PENDING_ADS: 'v2/servicePlan',
  EDIT_PENDING_ADS: 'v2/servicePlan',
  SUBSCRIPTIONPLAN_USERS: 'v2/servicePlan/mainPlans',
  VIEW_SUBSCRIPTION_PLANS: 'v2/servicePlan/getPlan',
  ADS_USERS: 'v2/users/ads/getAdsUsers',
  PARTICULAR_USERS: 'v2/servicePlan/getAdsRequestsByUsers',
  HOW_IT_WORKS: 'v2/adsInstructionTemplate',
  ACTIVE_ADS_USERS: 'v2/servicePlan/getActiveAdsRequests',
  ACTIVE_USERS_CSV: 'v2/servicePlan/getActiveAdsRequestsCsv',
  GET_REVENUE_REPORTS: 'v2/servicePlan/getRevenueReport',
  GET_REVENUE_REPORTS_CSV: 'v2/servicePlan/getRevenueReportCsv',
  CATEGORY_USERS: 'v2/interestCategories',
  INTEREST_TYPES: 'v2/interest',
  GET_CATEGORIES: 'v2/interestCategories/onlyName',
  ART_OF_WEEK: 'v2/artOfTheWeek/arts/topArts',
  ALL_ARTS: 'v2/artOfTheWeek/arts',
  ARTIST_OF_WEEK: 'v2/artistOfTheWeek/artist/topArtist',
  ALL_ARTISTS: 'v2/artistOfTheWeek/artist',
  CREATE_ARTISTS_OF_WEEK: 'v2/artistOfTheWeek',
  CREATE_ARTS_OF_WEEK: 'v2/artOfTheWeek',
  DELETE_ART: 'v2/artOfTheWeek',
  LANGUAGES: 'v2/languages',
  MASTERCLASS: 'v2/admin/masterclasses',
  Tags: 'v2/masterclasstags',
  AddTags: 'v2/masterclasstags',
  SUBSCRIPTION_PLANS: 'v2/masterClassSubscription',
  ADVERTISEMENTS: 'v2/admin/masterclassesAds',
  MASTERCLASSLISTING: 'v2/admin/masterclassesAds/masterClassAdsListing',
  GET_TRANSACTION_REPORTS: 'v2/admin/transaction',
  CLASSROOM_REPORTS_CSV: 'v2/transaction/revenueCsv',
  WELCOME_VIDEO: 'v2/welcomeVideo',
  PORTFOLIO_PLANS: 'v2/subscriptionPortfolio',
};
