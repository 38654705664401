export const masterClass = {
  title: 'Cours de maître',
  add: 'CRÉER UNE MASTER CLASS',
  listTitle: 'Titre',
  description: 'Description',
  actions: 'Actions',
  delete_success_message: 'La classe de maître a été supprimée avec succès',
  instructorName: `Nom de l'instructeur`,
  confirm_delete_title: 'Confirmer la suppression',
  confirm_delete_message: 'Etes-vous sûr de vouloir supprimer cette master class ?',
  masterClass_no_data: 'Aucune classe de maître trouvée',
  add_masterclass_title: 'Créer une classe de maître',
  update_masterclass_title: 'Modifier une classe de maître',
  view_masterclass_title: 'Voir les détails de la classe de maître',
  create_tag_success_message: 'La balise a été créée avec succès',
  create_success_message: 'La master class a été créée avec succès',
  update_success_message: 'La classe principale a été mise à jour avec succès',
  no_related_class: 'Aucune classe de maître associée trouvée',
  no_class_reports: 'Aucun rapport de classe trouvé',
  free: 'Gratuite',
  paid: 'Payé',
  date: 'Date',
  username: `Nom d'utilisateur`,
  amount: 'Montant',
  transaction_no_data: 'Aucun rapport de transaction trouvé',
  masterClass: 'Nom de la classedemaître',
  view_welcome_title: 'Voir les détails de la vidéo de bienvenue',
  update_welcome_title: 'Modifier une vidéo de bienvenue',
  add_welcome_title: 'Créer une vidéo de bienvenue',
  welcome_no_data: 'Aucune donnée de bienvenue trouvée',
  welcome_update_success_message: 'La vidéo de bienvenue a été mise à jour avec succès',
  welcome_title: 'Vidéo de bienvenue',
  form_fields: {
    title: {
      label: 'Titre*',
      placeholder: 'Titre',
      max_length_error_message: 'Le titre ne peut pas comporter plus de 50 caractères',
      required_error_message: 'Le champ titre est requis',
    },
    instructorName: {
      label: `Nom de l'instructeur*`,
      placeholder: `Nom de l'instructeur`,
      required_error_message: `Le champ du nom de l'instructeur est requis`,
    },
    description: {
      label: 'Description*',
      placeholder: 'Description',
      required_error_message: 'Le champ de description est requis',
    },
    ispaid: {
      label: 'EstPayé*',
      placeholder: 'EstPayé',
      required_error_message: 'Le champ de EstPayé est requis',
    },
    video: {
      label: 'URL de la vidéo*',
      placeholder: 'URL de la vidéo',
      required_error_message: 'Le champ URL de la vidéo est requis',
    },
    tags: {
      label: 'Mots clés*',
      placeholder: 'Mots clés',
      required_error_message: 'Le champ Balises est requis',
      max_length_error_message: `Au moins une étiquette est nécessaire`,
    },
    learningPoints: {
      label: 'Ce que vous apprendrez ?*',
      placeholder: 'Ce que vous apprendrez ?',
      required_error_message: 'Le champ Ce que vous apprendrez est requis',
      max_length_error_message: `Au moins un point d'apprentissage est requis`,
      image_required_message: `Ce que vous allez apprendre, le champ d'image est requis`,
    },
    relatedMasterClass: {
      label: 'Classe de maître connexe*',
      placeholder: 'Classe de maître connexe',
      required_error_message: 'Le champ de la classe principale associé est requis',
    },
    masterClassAds: {
      label: 'Publicités*',
      placeholder: 'Publicités',
      required_error_message: 'Le champ Publicités est requis',
    },
    show_video: {
      label: 'Afficher la vidéo de bienvenue*',
      placeholder: 'Afficher la vidéo de bienvenue',
    },
  },
};
