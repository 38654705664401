import { InitialPaginationParams } from '../../api/common/common.types';
import { Column } from '../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'desc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'title',
    label: 'subscriptionPlan.listTitle',
    width: '20%',
  },

  {
    id: 'price',
    label: 'subscriptionPlan.price',
    width: '20%',
  },
  {
    id: 'description',
    label: 'subscriptionPlan.description',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'actions',
    label: 'subscriptionPlan.actions',
    width: '10%',
    isNotSortable: true,
  },
];
