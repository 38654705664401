/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Stack,
  Button,
  CircularProgress,
  Container,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Typography,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../../../components/button/CustomLoadingButton';
import FormProvider from '../../../../components/hook-form/FormProvider';
import RHFTextField from '../../../../components/hook-form/RHFTextField';
import useSnackBar from '../../../../hooks/useSnackBar';
import { RootState } from '../../../../store/rootStore';
import PortfolioPlan from '../../../../models/Portfolio';
import { portfolioPlanApi } from '../../../../store/portfolioPlans/api/PortfolioPlans.api';
import { PortfolioActionTypes } from '../../../../store/portfolioPlans/slice/PortfolioPlans-slice.types';
import { CreatePortfolioSchema } from './create-portfolio.schema';
import { PortfolioPlanFormProps } from './PortfolioPlan-form.types';
import { colors } from '../../../../theme/sharedTheme';

const { updatePortfolioPlans, createPortfolioPlans } = portfolioPlanApi.endpoints;

// Example tag options

export default function PortfolioPlanForm({ currentPortfolioPlans, isUpdate, onClose, isEyeIconClicked }: PortfolioPlanFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PortfolioActionTypes>>();
  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues = useMemo<PortfolioPlan>(
    () => ({
      title: currentPortfolioPlans?.title || '',
      description: currentPortfolioPlans?.description || '',
      duration: currentPortfolioPlans?.duration || 1,
      isPaid: currentPortfolioPlans?.isPaid !== undefined ? currentPortfolioPlans?.isPaid : 1,
      price: currentPortfolioPlans?.price || 0,
    }),
    [currentPortfolioPlans],
  );

  const methods = useForm<PortfolioPlan>({
    resolver: yupResolver(CreatePortfolioSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    handleSubmit,
    watch,
    reset,
    trigger,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentPortfolioPlans]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentPortfolioPlans?.title || '',
      description: currentPortfolioPlans?.description || '',
      duration: currentPortfolioPlans?.duration || 1,
      isPaid: currentPortfolioPlans?.isPaid !== undefined ? currentPortfolioPlans?.isPaid : 1,
      price: currentPortfolioPlans?.price || 0,
    };
    methods.reset(updatedDefaultValues);
  }, [currentPortfolioPlans, isUpdate]);

  useEffect(() => {
    if (values.isPaid === 0) {
      setValue('price', 0);
    }
  }, [values.isPaid, setValue]);

  const onSubmit = async () => {
    if (!isUpdate) {
      create();
    } else {
      update();
    }
  };

  const create = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        const data = {
          title: values?.title,
          duration: values?.duration,
          price: values?.price,
          description: values?.description,
          isPaid: values?.isPaid,
        };
        await dispatch(createPortfolioPlans(data));
        reset(defaultValues);
        dispatch(alertSuccess(t('portfolioPlans.create_success_message')));
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    }
  };

  const update = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid && currentPortfolioPlans?.id) {
        const data = {
          title: values?.title,
          duration: values?.duration,
          price: values?.price,
          description: values?.description,
          isPaid: values?.isPaid,
        };
        await dispatch(updatePortfolioPlans(currentPortfolioPlans?.id, data));
        reset(defaultValues);
        dispatch(alertSuccess(t('portfolioPlans.update_success_message')));
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const isPaidOption = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  const durationOption = [
    { label: '1 Month', value: 1 },
    { label: '3 Months', value: 3 },
    { label: '6 Months', value: 6 },
  ];

  return (
    <>
      <Backdrop
        sx={{
          color: colors.yellow,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure this value is high enough
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h2>{t('common.loading')}</h2>
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={`artgapi-modal`} container spacing={3}>
          <Grid className="artgapi-plan-form" item xs={12} md={12}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name={'title'}
                    placeholder={t('portfolioPlans.form_fields.title.placeholder')}
                    label={t('portfolioPlans.form_fields.title.label')}
                    disabled={isEyeIconClicked}
                  />
                  <FormControl fullWidth>
                    <InputLabel
                      id="duration-label"
                      sx={{
                        transform: 'translate(0px, 0px) scale(0.95)',
                        position: 'relative !important',
                        maxWidth: '0',
                        minWidth: '100%',
                        color: 'rgb(171, 171, 171)',
                        marginBottom: '0.5rem',

                        '& .css-to7sdl-MuiFormControl-root': {
                          marginTop: '4px !important',
                        },
                      }}
                    >
                      {t('portfolioPlans.form_fields.duration.placeholder')}
                    </InputLabel>
                    <Controller
                      name="duration"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Select
                            {...field}
                            sx={{
                              width: '100%',
                              height: '48px',
                              borderRadius: '0',
                              '& .MuiSelect-select': {
                                width: '100%',
                                height: '48px !important',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '12.5px 14px',
                              },
                              '& .MuiOutlinedInput-root': {
                                marginTop: '-8px !important',
                              },
                              '& .MuiOutlinedInput-input': {
                                border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                borderColor: error && '#d32f2f',
                                borderRadius: '0',
                              },
                              '& .MuiSelect-select:hover': {
                                borderColor: '#141415 !important',
                              },
                            }}
                            labelId="duration-label"
                            id="durationId"
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={isEyeIconClicked}
                          >
                            <MenuItem key="select" selected value="0">
                              {t('common.selectDuration')}
                            </MenuItem>
                            {/* Map over the list of countries */}
                            {durationOption?.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* Display error message if there's an error for countryId */}
                          {error && (
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: '0.75rem',
                                lineHeight: '1.66',
                                letterSpacing: '0.03333em',
                                textAlign: 'left',
                                marginTop: '3px',
                                marginRight: '14px',
                                marginBottom: '0',
                                marginLeft: '14px',
                                color: '#d32f2f',
                              }}
                            >
                              {error?.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel
                      id="ispaid-label"
                      sx={{
                        transform: 'translate(0px, 0px) scale(0.95)',
                        position: 'relative !important',
                        maxWidth: '0',
                        minWidth: '100%',
                        color: 'rgb(171, 171, 171)',
                        marginBottom: '0.5rem',

                        '& .css-to7sdl-MuiFormControl-root': {
                          marginTop: '4px !important',
                        },
                      }}
                    >
                      {t('portfolioPlans.form_fields.ispaid.placeholder')}
                    </InputLabel>
                    <Controller
                      name="isPaid"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Select
                            {...field}
                            sx={{
                              width: '100%',
                              height: '48px',
                              borderRadius: '0',
                              '& .MuiSelect-select': {
                                width: '100%',
                                height: '48px !important',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '12.5px 14px',
                                paddingRight: '110px !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                marginTop: '-8px !important',
                              },
                              '& .MuiOutlinedInput-input': {
                                border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                borderColor: error && '#d32f2f',
                                borderRadius: '0',
                              },
                              '& .MuiSelect-select:hover': {
                                borderColor: '#141415 !important',
                              },
                            }}
                            labelId="ispaid-label"
                            id="id"
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={isEyeIconClicked}
                          >
                            {/* Map over the list of countries */}
                            {isPaidOption?.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* Display error message if there's an error for countryId */}
                        </>
                      )}
                    />
                  </FormControl>
                  <RHFTextField
                    name={'price'}
                    placeholder={t('portfolioPlans.form_fields.price.placeholder')}
                    label={t('portfolioPlans.form_fields.price.label')}
                    disabled={isEyeIconClicked || values?.isPaid === 0}
                  />
                  <RHFTextField
                    placeholder={t('portfolioPlans.form_fields.description.placeholder')}
                    name={'description'}
                    label={t('portfolioPlans.form_fields.description.label')}
                    disabled={isEyeIconClicked}
                    multiline
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        border: '0 !important',
                      },
                    }}
                    rows={4} // Adjust the number of rows based on your preference
                  />
                </Box>
              </Box>

              {/* Submit Button */}
              {!isEyeIconClicked && (
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <CustomLoadingButton
                    loading={loading || isSubmitting}
                    label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                    onClick={onSubmit}
                  />
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
