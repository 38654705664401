export const common = {
  published: 'Publier',
  discard: 'Annuler',
  save_and_publish: 'Enregistrer & Publier',
  search: 'Rechercher',
  actions: 'Actions',
  delete: 'Supprimer',
  cancel: 'Annuler',
  confirm: 'confirmer',
  user_reports: `Rapports d'utilisateurs`,
  revenue_reports: `Rapports de revenus`,
  transaction_reports: 'Rapports de transactions',
  ads_users_report: 'Rapports sur les annonces actives',
  Logout: 'Se déconnecter',
  confirm_logout_title: 'Confirmer la déconnexion',
  confirm_logout_message: 'Êtes-vous sûr de vouloir vous déconnecter ?',
  filter: 'Choisir une catégorie',
  masterClassAds: 'Sélectionnez une publicité',
  clear: 'Claire',
  no_image_found: 'Aucune image trouvée',
  arts_of_week: 'Arts de la semaine',
  artists_of_week: 'Artiste de la semaine',
  no_data: 'Aucune donnée disponible',
  classroom_reports: 'Rapports de classe',
  transaction_id: 'ID de transaction',
  plan_name: 'Nom du Régime',
  only_5_boxes: 'Vous pouvez sélectionner au maximum 5 arts de la semaine uniquement.',
  created_five_artists: 'Artistes de la semaine ajoutés avec succès',
  created_five_arts: 'Arts de la semaine ajoutés avec succès',
  rowsperpage: 'Lignes par page',
  export: 'Exporter',
  confirm_delete_artist_message: 'Etes-vous sûr de vouloir supprimer cet Artiste ?',
  confirm_delete_art_message: 'Êtes-vous sûr de vouloir supprimer cet art ?',
  something_wrong_happend: `Quelque chose s'est mal passé`,
  title: 'Titre',
  price: 'Prix',
  image: 'Image',
  loading: 'chargement',
  status: 'Afficher la vidéo',
  selectDuration: 'Sélectionnez la durée',
};
