/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack, CircularProgress, Backdrop } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../button/CustomLoadingButton';
import FormProvider from '../../hook-form/FormProvider';
import RHFTextField from '../../hook-form/RHFTextField';
import useSnackBar from '../../../hooks/useSnackBar';
import { RootState } from '../../../store/rootStore';
import { colors } from '../../../theme/sharedTheme';
import SubscriptionPlans from '../../../models/SubscriptionPlans';
import { CreateSubscriptionPlansSchema } from './create-subscriptionPlan.schema';
import { subscriptionPlansApi } from '../../../store/subscriptionPlans/api/SubscriptionPlans.api';
import { SubscriptionPlansFormProps } from './SubscriptionPlans-form.types';
import { SubscriptionPlansActionTypes } from '../../../store/subscriptionPlans/slice/SubscriptionPlans-slice-types';

const { updateSubscriptionPlan, createSubscriptionPlan } = subscriptionPlansApi.endpoints;

export default function SubscriptionPlanForm({ currentSubscriptionPlan, isUpdate, onClose, isEyeIconClicked }: SubscriptionPlansFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, SubscriptionPlansActionTypes>>();
  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues = useMemo<SubscriptionPlans>(
    () => ({
      title: currentSubscriptionPlan?.title || '',
      description: currentSubscriptionPlan?.description || '',
      price: currentSubscriptionPlan?.price || 0,
    }),
    [currentSubscriptionPlan],
  );

  const methods = useForm<SubscriptionPlans>({
    resolver: yupResolver(CreateSubscriptionPlansSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentSubscriptionPlan]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentSubscriptionPlan?.title || '',
      description: currentSubscriptionPlan?.description || '',
      price: currentSubscriptionPlan?.price || 0,
    };
    methods.reset(updatedDefaultValues);
  }, [currentSubscriptionPlan, isUpdate]);

  const onSubmit = async () => {
    if (!isUpdate) {
      create();
    } else {
      update();
    }
  };

  const create = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid) {
        await dispatch(createSubscriptionPlan(values));
        reset(defaultValues);
        dispatch(alertSuccess(t('subscriptionPlan.create_success_message')));
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const update = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid && currentSubscriptionPlan?.id) {
        const data = {
          title: values.title,
          description: values.description,
          price: values.price,
        };
        await dispatch(updateSubscriptionPlan(currentSubscriptionPlan?.id, data));
        reset(defaultValues);
        dispatch(alertSuccess(t('subscriptionPlan.update_success_message')));
        onClose();
      }
    } catch (err) {
      const error = err as Error; // Type assertion
      if (error.message === 'Master Class Plan already exist') {
        dispatch(alertError(t('subscriptionPlan.title_already_exist')));
      } else {
        dispatch(alertError(t('common.something_wrong_happend')));
      }
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: colors.yellow,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure this value is high enough
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h2>{t('common.loading')}</h2>
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={`artgapi-modal`} container spacing={3}>
          <Grid className="artgapi-plan-form" item xs={12} md={12}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name={'title'}
                    placeholder={t('subscriptionPlan.form_fields.title.placeholder')}
                    label={t('subscriptionPlan.form_fields.title.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'price'}
                    placeholder={t('subscriptionPlan.form_fields.price.placeholder')}
                    label={t('subscriptionPlan.form_fields.price.placeholder')}
                    disabled={isEyeIconClicked || !!isUpdate}
                  />
                  <RHFTextField
                    placeholder={t('subscriptionPlan.form_fields.description.placeholder')}
                    name={'description'}
                    label={t('subscriptionPlan.form_fields.description.label')}
                    disabled={isEyeIconClicked}
                    multiline
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        border: '0 !important',
                      },
                    }}
                    rows={4} // Adjust the number of rows based on your preference
                  />
                </Box>
              </Box>

              {/* Submit Button */}
              {!isEyeIconClicked && (
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <CustomLoadingButton
                    loading={loading || isSubmitting}
                    label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                    onClick={onSubmit}
                  />
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
